import {styled} from '@mui/material/styles'
import {Box, Button} from '@mui/material'

import TrackCustomPreference from 'consentCheck'

export const ModalBox = styled(props => {
  return (
    <Box
      sx={{
        paddingBottom: {xs: '20px', sm: '30px', md: '30px'},
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '10px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        width: {xs: '95%', sm: '80%', md: '55%', lg: '40%', xl: '35%'},
      }}
      {...props}
    />
  )
})({})

export const LoginButton = styled(props => {
  return (
    <Button
      onClick={() => {
        TrackCustomPreference({
          action: 'Link Clicked',
          properties: {url: props.href, text: 'Log In', meaning: 'Log In link'},
        })
      }}
      target='_blank'
      sx={{
        marginRight: {xs: '20px', sm: '40px'},
        width: {xs: '120px', sm: '160px'},
        height: '36px',
        borderRadius: '4px',
        boxShadow: '0px 0px 6px 0px #00000040',
        background: 'linear-gradient(180deg, rgba(134, 194, 46, 0.8) 0%, #86C22E 100%)',
        color: 'black',
      }}
      {...props}
    >
      {props.t('buttons.login')}
    </Button>
  )
})({})

export const RegisterButton = styled(props => {
  return (
    <Button
      onClick={() => {
        TrackCustomPreference({
          action: 'Link Clicked',
          properties: {url: props.href, text: 'Register', meaning: 'Register link'},
        })
      }}
      target='_blank'
      sx={{
        width: {xs: '120px', sm: '160px'},
        height: '36px',
        borderRadius: '4px',
        boxShadow: '0px 0px 6px 0px #00000040',
        background: 'linear-gradient(180deg, rgba(215, 182, 111, 0.81) 0%, #D7B66F 100%)',
        color: 'black',
      }}
      {...props}
    >
      {props.t('buttons.register.register')}
    </Button>
  )
})({})

export const GreyBox = styled(props => {
  return (
    <Box
      padding={'15px 25px 15px 25px'}
      sx={{
        marginBottom: '30px',

        borderRadius: '10px',
        backgroundColor: 'rgba(245, 245, 245, 0.33)',
        boxShadow: '0px 0px 4px 0px #00000040',
      }}
      {...props}
    />
  )
})({})

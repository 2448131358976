import React, {useMemo, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'
import {Button, Modal, Box, Typography, Autocomplete, TextField} from '@mui/material'

import {useMediaQuery} from 'hooks'
import TrackCustomPreference from 'consentCheck'
import {euCountries, usCountries} from 'config/whitelistedCountries'

import {ModalBox, LoginButton, RegisterButton} from './ButtonBuyGoldModal.styles'
import {loginUSUrl, loginEUUrl, registerEUUrl, registerUSUrl} from 'config'

export function ButtonBuyGoldModal() {
  const {t} = useTranslation()
  const inputRef = useRef(null)
  const isMobile = useMediaQuery('sm', 'down')

  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const [selectedValue, setSelectedValue] = useState({label: 'Germany', value: 'DE'})
  const [inputText, setInputText] = useState(selectedValue.label)

  const mergedCountries = [...usCountries, ...euCountries].sort((a, b) =>
    a.label.localeCompare(b.label)
  )

  const isEurope = useMemo(
    () => !!euCountries.find(item => item.value === selectedValue.value),
    [selectedValue.value]
  )

  const loginUrl = useMemo(() => (isEurope ? loginEUUrl : loginUSUrl), [isEurope])

  const registerUrl = useMemo(() => (isEurope ? registerEUUrl : registerUSUrl), [isEurope])

  return (
    <>
      <Button
        onClick={() => {
          handleOpen()
          TrackCustomPreference({
            action: 'Button Clicked',
            properties: {
              text: t('buttons.buyGold'),
              meaning: 'Open register/login popup',
            },
          })
        }}
        target={isMobile ? '_self' : '_blank'}
        sx={{
          borderRadius: '4px',
          boxShadow: '0px 0px 6px 0px #00000040',
          background: 'linear-gradient(180deg, rgba(215, 182, 111, 0.81) 0%, #D7B66F 100%)',
          color: 'black',
          height: {xs: '38px', sm: '38px'},
          width: {xs: '138px', sm: '145px'},
        }}
      >
        {t('buttons.buyGold')}
      </Button>
      <Modal open={open} onClose={handleClose}>
        <ModalBox>
          <CloseIcon
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: '35px',
              right: '15px',
              cursor: 'pointer',
            }}
          />
          <Typography padding={'30px'} variant='h3'>
            {t('modal.buyModal.title')}
          </Typography>
          <Box width={isMobile ? '90%' : '65%'}>
            <Box
              marginBottom={3}
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Autocomplete
                size='small'
                id='country'
                fullWidth
                value={selectedValue}
                onChange={(_, newValue) => {
                  setSelectedValue(newValue)
                  inputRef.current?.blur()
                }}
                inputValue={inputText}
                onInputChange={(_, newValue) => {
                  setInputText(newValue)
                }}
                onFocus={() => setInputText('')}
                onBlur={() => setInputText(selectedValue.label)}
                options={mergedCountries}
                getOptionLabel={option => option.label}
                disableClearable
                renderOption={(props, option) => {
                  const {key, ...optionProps} = props
                  return (
                    <Box key={key} component='li' sx={{fontSize: 16}} {...optionProps}>
                      {option.label}
                    </Box>
                  )
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    size='medium'
                    label={t('modal.buyModal.countrySelectLabel')}
                    InputLabelProps={{shrink: true}}
                    inputRef={inputRef}
                    slotProps={{
                      htmlInput: {
                        ...params.inputProps,
                        autoComplete: 'new-password',
                      },
                    }}
                  />
                )}
              />
            </Box>
            <Box
              paddingBottom={'20px'}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-evenly',
              }}
            >
              <LoginButton href={loginUrl} t={t} />
              <RegisterButton href={registerUrl} t={t} />
            </Box>
          </Box>
        </ModalBox>
      </Modal>
    </>
  )
}

export default ButtonBuyGoldModal
